import { createRouter, createWebHistory } from "vue-router";

function lazyLoad(view) {
      return () => import(`../components/pages/${view}.vue`)
}

const routes = [
      {
            path: '/',
            redirect: '/form',
      },
      {
            path: '/form',
            name: 'formComponent',
            component: lazyLoad('form/FormComponent'),
            meta: { layout: "default", title: "formComponent" },
            props: true,
      },
];

const router = new createRouter({
      history: createWebHistory(),
      routes,
      linkActiveClass: 'active-link',
      linkExactActiveClass: 'exact-active-link',
});

export default router;